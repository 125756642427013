<template>
  <div class="container-profile">
    <div class="profileBanner"></div>
    <div class="row mb-4">
      <div
        class="col-3 mr-4 profile-card profile-user-info d-flex flex-column justify-content-center align-items-center"
      >
        <div class="fs-65 mb-2">
          <font-awesome-icon icon="fa-regular fa-building" />
        </div>
        <div class="fs-18 mb-2">
          <span class="text-bold uppercase">{{ company?.name }}</span>
        </div>
        <div class="mb-2" v-if="company.website">
          <a :href="company.website" target="_blank">{{ company.website }}</a>
        </div>
      </div>

      <div class="col-4 mr-auto d-flex flex-column profile-card">
        <div class="row p-3">
          <div class="uppercase fs-14 text-grey">Company</div>
          <div
            v-if="user?.role?.id == '1889B2E8093'"
            class="clickable"
            @click="setEditCompany"
            style="position: absolute; right: 0; margin-right: 20px"
          >
            <font-awesome-icon icon="fa-solid fa-pencil" />
          </div>
        </div>

        <div class="row mt-4 mb-4 fs-14">
          <div class="col-12 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">Name</span>
            <span v-if="company?.name">{{ company?.name }}</span>
            <span v-else>No company name available</span>
          </div>
        </div>

        <div class="row mb-4 fs-14">
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">email</span>
            <span v-if="company?.email">{{ company?.email }}</span>
            <span v-else>No company email adress available</span>
          </div>
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">phone</span>
            <span v-if="company?.phone">{{ company?.phone }}</span>
            <span v-else>No company phone number available</span>
          </div>
        </div>

        <div class="row mb-auto fs-14">
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">address</span>
            <span v-if="company?.address">{{ company?.address }}</span>
            <span v-else>No company address available</span>
          </div>
          <div class="col-6 d-flex flex-column">
            <span class="text-blue fs-11 uppercase">website</span>
            <a v-if="company?.website" :href="company?.website">{{
              company?.website
            }}</a>
            <span v-else>No company website available</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <employees-company :company="company" :key="company.id" />
    </div>
    <company-modal
      id="company-modal"
      @addCompany="addCompany"
      :editCompany="editCompany"
    />
  </div>
</template>
	  
	  <script>
import Api from "@/Api";

import store from "@/store";

import EmployeesCompany from "@/pages/EmployeesCompany";
import CompanyModal from "@/components/Modals/CompanyModal";

export default {
  name: "Company",
  components: { EmployeesCompany, CompanyModal },
  mixins: [],
  store,
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "";
      },
    };
  },
  data() {
    return {
      editCompany: null,
      company: {
        id: null,
        name: null,
        address: null,
        phone: null,
        email: null,
        website: null,
      },
    };
  },
  computed: {
    user: {
      get() {
        if (this.$store.state.user) return this.$store.state.user;
        else {
          return {
            user: {
              id: null,
              firstname: null,
              lastname: null,
              company: {
                id: null,
                name: null,
                address: null,
                phone: null,
                email: null,
                website: null,
              },
              role: { name: null, description: null },
              position: null,
              email: null,
              phone: null,
            },
          };
        }
      },
    },
  },
  created() {},
  mounted() {
    this.getCompany();
  },
  methods: {
    getCompany() {
      Api.get("company/" + this.id).then((response) => {
        this.company = response?.data;
      });
    },
    setEditCompany() {
      this.editCompany = this.user.company;
      this.$bus.$emit("open-modal", "company-modal");
    },
    addCompany(company) {
      if (company) {
        if (company.id) {
          Api.put("company/" + company.id, company)
            .then((response) => {
              this.$notify({
                type: "success",
                title: "Action Successful",
                text: "Company successfully updated.",
                duration: 8000,
              });
              this.company = response.data;
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {});
        }
      }
    },
  },
};
</script>
	  
	  <style lang="scss">
</style>
	  